import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import DownloadProp from '../components/DownloadProp';

const ContactPage = () => (
    <Layout>
        <SEO title="Contacto - Aguaman" description="" pathname="/contacto" />
        <section className="contact-prop">
            <div className="contact-prop-content">
                <div className="main-message">
                    <h1>Pregunta sin compromiso</h1>
                    <p className="contact-subtitle">
                        Escríbenos por cualquier duda por más pequeña que parezca. Estaremos
                        contentos de responderte.
                    </p>
                    <div className="links-content">
                        <div>
                            <a
                                className="button button-outlined"
                                href="mailto:info@aguamandelivery.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="logo-mail"></div>&nbsp; Escribir correo
                            </a>
                        </div>
                        <div>
                            <a
                                className="button button-success"
                                href="https://wa.me/584247710732?text=Hola%2C+aguaman.+Me+interesa+el+servicio%2C+podr%C3%ADas+darme+m%C3%A1s+informaci%C3%B3n%3F"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="logo-whatsapp"></div>&nbsp; Whatsapp
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-photo-contact"></div>
            </div>
        </section>
    </Layout>
);

export default ContactPage;
